import { storageUtils } from "./utils"

const LocalStorageServiceKeys: {
    TokenAccess: () => string
    TokenRefresh: () => string
    SocialComplete: () => string
    ArchiveModalVisible: () => string
    SubUserList: () => string
    MainUserLoggedOut: () => string
    CoachingMomentSeedQuestion: (idx: string) => string
    SurveysPageRedirected: (userId: number) => string
} = {
    TokenAccess: (): string => "tokenAccess",
    TokenRefresh: (): string => "tokenRefresh",
    SocialComplete: (): string => "socialComplete",
    ArchiveModalVisible: (): string => "show-archive-modal",
    SubUserList: (): string => "subUserList",
    MainUserLoggedOut: (): string => "mainUserLoggedOut",
    CoachingMomentSeedQuestion: (idx: string): string => `cmcsq_${idx}`,
    SurveysPageRedirected: (id: number): string => `sp_redirect-${id}`
}

class LocalStorageService extends Storage {
    static setItem<T>(key: ReturnType<() => keyof typeof LocalStorageServiceKeys>, value: T): void {
        localStorage.setItem(key, storageUtils.setStorageItemValue<T>(value))
    }

    static getItem<T>(key: ReturnType<() => keyof typeof LocalStorageServiceKeys>): T | null {
        return storageUtils.getStorageItemValue<T>(localStorage.getItem(key))
    }

    static removeItem(key: ReturnType<() => keyof typeof LocalStorageServiceKeys>): void {
        localStorage.removeItem(key)
    }

    static clear(): void {
        localStorage.clear()
    }
}

export { LocalStorageService, LocalStorageServiceKeys }
